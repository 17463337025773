import type { StyleRule } from '@archipro-design/aria';
import { pxToRem } from '@archipro-design/aria';

interface ClickToRevealProps {
    clicked?: boolean;
}

export const ClickToRevealContainer: StyleRule<ClickToRevealProps> = ({
    theme,
    clicked,
}) => ({
    '& > div': {
        fontSize: pxToRem(20),
        cursor: clicked ? 'inherit' : 'pointer',
        '&:hover': {
            textDecoration: clicked ? 'inherit' : 'underline',
        },
    },
    '& > a': {
        fontSize: pxToRem(20),
        lineHeight: theme.siteVariables.lineHeightSmallest,
        '&:hover': {
            textDecoration: 'underline',
        },
    },
});
